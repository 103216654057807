import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationCookieName, Subdomain } from '@regas/bruce';
import { CookieService, LoggingService, OriginService, SettingsService } from '@regas/shared';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Credentials } from '../../models/interface/credentials.interface';
import { Organisation } from '../../models/interface/organisation.interface';
import { LoginStatus } from '../../models/enum/error-codes.enum';
import { LoginState } from '../../models/interface/login-state.interface';
import { DefaultLoginResponse } from '../../models/interface/default-login-response.interface';
import { WhitelistResponse, WhitelistStatus } from '../../models/WhitelistResponse';
import { LoginStateStatus } from '../../models/LoginStateStatus';
import { LoginStateResolver } from '../../utils/state-resolver/login-state-resolver';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';
import { HttpFactory } from '../../utils/http-factory/http-factory';
import { LogFactory } from '../../utils/log-factory/log-factory';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';
import { UserAuthInfo } from "./UserAuthInfo";

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private organisations: Organisation[] = [];
  private readonly state = new BehaviorSubject<LoginState>({
    loading: false,
  });

  constructor(
    private readonly httpClient: HttpClient,
    private readonly settingsService: SettingsService,
    private readonly cookieService: CookieService,
    private readonly originService: OriginService,
    private readonly loggingService: LoggingService
  ) {}

  public getState(): Observable<LoginState> {
    return this.state.asObservable();
  }

  public getOrganisations(): Organisation[] {
    return this.organisations;
  }

  public login(state: LoginState): Observable<void> {
    this.state.next({ ...state, loading: true });
    switch (LoginStateResolver.resolve(state)) {
      case LoginStateStatus.TWO_FACTOR:
        return this.loginTwoFactor(state);
      case LoginStateStatus.CREDENTIALS:
        return this.loginUser(state.credentials as Credentials);
      default:
        return throwError('Cannot login without login details');
    }
  }

  public checkUrlWhiteList(gotoUrl: URL, token: string): Observable<boolean> {
    if (!this.state.value.loading) {
      this.state.next({ ...this.state.value, loading: true });
    }
    return this.httpClient
      .get<WhitelistResponse>(
        this.originService.getUrl(
          Subdomain.Api,
          `${REGAS_IAM_ENDPOINT.authentication}/whitelist-status`,
        ).href,
        {
          headers: HttpFactory.getHeaders('Authorization', 'Bearer ' + token),
          params: HttpFactory.getParams('url', gotoUrl.href),
        },
      )
      .pipe(
        map(
          (response: WhitelistResponse) =>
            response.status === WhitelistStatus.SUCCESS,
        ),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        catchError((error: any) =>
          this.loggingService
            .log(LogFactory.getLoginError(gotoUrl as URL, error), token)
            .pipe(map(() => false)),
        ),
      );
  }

  clearState(): void {
    this.state.next({ loading: false });
  }

  private loginUser(
    credentials: Credentials,
  ): Observable<void> {
    return this.httpClient
      .post(
        this.originService.getUrl(
          Subdomain.Api,
            `${REGAS_IAM_ENDPOINT.authentication}/authenticate/user`,
        ).href,
        credentials,
      )
      .pipe(
        catchError(error => {
          this.state.next({ ...this.state.value, loading: false });
          return throwError(error);
        }),
        map((result: DefaultLoginResponse) => {
          this.updateState(result, credentials.organisationSubstitution);
          localStorage.setItem(
            LocalStorageKeys.ORGANISATION_SUBSTITUTION,
            this.state.value.organisation?.substitution || '',
          );
        }),
      );
  }

  private loginTwoFactor(loginState: LoginState): Observable<void> {
    if (!loginState.organisation || !loginState.twoFactor) {
      throw new Error(
        'Impossible to login without organisation and twoFactor information',
      );
    }

    const twoFactorData = {
      temporaryToken: loginState.token,
      organisationCode: loginState.organisation.code,
      secretKey: loginState.twoFactor.secretKey,
      verificationCode: loginState.twoFactor.verificationCode,
      phonePrefix: this.state.value.phone?.prefix,
      phoneNumber: this.state.value.phone?.number,
    };

    return this.httpClient
      .post(
        this.originService.getUrl(
          Subdomain.Api,
          `${REGAS_IAM_ENDPOINT.authentication}/twofactorvalidation`,
        ).href,
        twoFactorData,
      )
      .pipe(
        catchError(response => {
          if (
            response.status !== 401 &&
            !this.state.value.twoFactor?.secretKey
          ) {
            return throwError(
              new Error('Secretkey required but not available for 2FA'),
            );
          }

          this.state.next({
            ...this.state.value,
            loading: false,
            twoFactor: {
              ...this.state.value.twoFactor,
              verificationCode: undefined,
            },
          });

          return throwError(response);
        }),
        map((result: DefaultLoginResponse) => this.updateState(result)),
      );
  }

  // todo: refactor that...
  private updateState(
    response: DefaultLoginResponse,
    organisationSubsitution?: string,
  ): void {
    this.state.value.loading = false;
    this.state.value.error = this.getError(response);
    this.state.value.token = response.token
      ? response.token
      : this.state.value.token;
    this.state.value.refreshToken = response.refreshToken
      ? response.refreshToken
      : this.state.value.refreshToken;
    this.state.value.twoFactor = response.secretKey
      ? {
          ...this.state.value.twoFactor,
          secretKey: response.secretKey,
        }
      : this.state.value.twoFactor;
    this.state.value.twoFactorType = response.twoFactorType
      ? response.twoFactorType
      : this.state.value.twoFactorType;
    this.state.value.phone = response.phonePrefix
      ? {
          prefix: response['phonePrefix'],
          number: response['phoneNumberSuffix'],
          length: response['phoneNumberLength'],
        }
      : this.state.value.phone;
    this.organisations = response.organisations
      ? response.organisations
      : this.organisations;
    if (organisationSubsitution) {
      this.state.value.organisation = this.getOrganisation(
        organisationSubsitution,
      );
    }
    this.updateCookies();
    this.updateOrganisation();

    this.state.next({ ...this.state.value });
  }

  private updateCookies(): void {
    if (!!this.state.value.refreshToken && !!this.state.value.token) {
      this.setAuthenticationCookies(
        this.state.value.token,
        this.state.value.refreshToken,
      );
    }
  }

  private updateOrganisation(): void {
    if (this.organisations?.length === 1) {
      this.state.value.organisation = this.organisations[0];
      localStorage.setItem(
        LocalStorageKeys.ORGANISATION_SUBSTITUTION,
        this.state.value.organisation.substitution || '',
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getError(response: any): string | undefined {
    return response['status'] &&
      response['status'] !== LoginStatus.TwoFactor &&
      response['status'] !== LoginStatus.TwoFactorSetup
      ? response['status']
      : undefined;
  }

  private setAuthenticationCookies(
    accessToken: string,
    refreshToken: string,
  ): void {
    const {
      xsrf,
      access,
      refresh,
    } = this.settingsService.getAuthenticationCookies();

    this.cookieService.set({
      ...xsrf,
      value: uuid(),
    });

    this.cookieService.set({
      ...access,
      value: accessToken,
    });

    this.cookieService.set({
      ...refresh,
      value: refreshToken,
    });
  }

  getOrganisation(substitution: string): Organisation | undefined {
    return this.organisations.find(
      organisation => organisation.substitution === substitution,
    );
  }

  getUserAuthInfo(): Observable<UserAuthInfo> {
    const accessToken = this.cookieService.get(
        AuthenticationCookieName.Access,
    ) as string;

    const refreshToken = this.cookieService.get(
        AuthenticationCookieName.Refresh,
    ) as string;

    const userAuthInfo = new UserAuthInfo(accessToken, refreshToken);

    if (userAuthInfo.isAuthenticated()) {
      return this.confirmAuthentication(userAuthInfo);
    } else {
      return of(this.clearUserAuthInfo())
    }

  }

  private confirmAuthentication(userAuthInfo: UserAuthInfo) {
    return this.httpClient
        .get(
            this.originService.getUrl(
                Subdomain.Api,
                'security/auth-ping',
            ).href,
            {
              headers: HttpFactory.getHeaders('Authorization', 'Bearer ' + userAuthInfo.accessToken),
            }
        )
        .pipe(
            map(
                () => userAuthInfo,
            ),
            catchError((httpErrorResponse: HttpErrorResponse) => {
              console.warn("User unauthenticated", httpErrorResponse)
              return of(this.clearUserAuthInfo())
            })
        )
  }

  clearUserAuthInfo(): UserAuthInfo {
    this.cookieService.delete(AuthenticationCookieName.Access)
    this.cookieService.delete(AuthenticationCookieName.Refresh)
    return UserAuthInfo.anonymous()
  }

}
