import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IdpService } from '../../services/idp/idp.service';
import { ClientRegistrationService } from '../../services/registration/client/client-registration.service';
import { LanguageService } from '../../services/language/language.service';

@Injectable({
  providedIn: 'root',
})
export class IdpRegistrationRedirectGuard implements CanActivate {
  constructor(
    private readonly idpService: IdpService,
    private readonly router: Router,
    private readonly registrationService: ClientRegistrationService,
    private readonly languageService: LanguageService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const substitution = route.paramMap.get('substitution') as string;
    const mode: 'CLIENT' | 'EMPLOYEE' = route.data.mode;
    const validationCode = route.queryParamMap.get('validationcode') as string;

    return this.idpService.checkIdp(
      substitution,
      ({ status }) =>
        iif(
          () => status === 'ENABLED',
          this.registrationService
            .getEmailForActivationRequest(validationCode)
            .pipe(
              switchMap(({ emailAddress }) => {
                this.languageService.initLanguage();
                const language = this.languageService.getLanguage();
                const registerPayload = {
                  emailAddress,
                  validationCode,
                  language,
                };
                return iif(
                  () => mode === 'CLIENT',
                  this.registrationService.registerIdpClient(registerPayload),
                  this.registrationService.registerIdpEmployee(registerPayload),
                ).pipe(
                  map(({ url }) => {
                    if (url) {
                      window.location.href = url;
                    } else {
                      this.router.navigate([
                        `/${substitution}/idp-activate-account-mail-info`,
                      ]);
                    }
                    return false;
                  }),
                  catchError(() => {
                    this.router.navigate([`/idp-error/ACTIVATION_ERROR`]);
                    return of(false);
                  }),
                );
              }),
              catchError(() => {
                this.router.navigate([`/idp-error/ACTIVATION_NOT_FOUND`]);
                return of(false);
              }),
            ),
          of(true),
        ),
      () => of(true),
    );
  }
}
