import { logInVerb } from './log-in-verb';
import { password } from './password';
import { passwordRequired } from './passwordRequired';
import { usernameRequired } from './usernameRequired';
import { username } from './username';
import { code } from './code';

export const en = {
  codeRequired: 'Code is required',
  fixedLength: `${code.en} must be 6 numbers long`,
  company: 'myneva',
  email: 'Email address',
  emailRequired: 'Email address is required',
  enterTheAuthenticatorCode: 'Enter the code provided by the Authenticator App',
  enterTheSmsCode: 'Enter the code sent to',
  error: {
    chooseSafePassword:
      'Choose a password of at least 8 characters, in capital and lowercase letters and at least one number.',
    attemptsLeft1: 'Only 1 more attempt available.',
    attemptsLeft2: '2 more attempts available.',
    invalidCredentials: 'Invalid email address and/or password.',
    invalidPasswordChangeData:
      'The password change request expired or is invalid.',
    invalidVerificationCode: 'Invalid verification code.',
    ipRestricted:
      'There is no access to this organisation from this IP address.',
    activationExpired:
      'The activation process has failed. Please try again. If you are not able to find the problem, please contact your application manager or contact person.',
    idpError:
      'We were unable to retrieve login information from the identity provider. Please try again or contact your application manager or contact person.',
    userInactive:
      'Your account is deactivated. Please contact your application manager or contact person.',
    userNotFound:
      'There is no account matching your login details. Please contact your application manager or contactperson.',
    login: 'Login is not possible at the moment.',
    maximumReached:
      'Maximum number of attempts reached. Please try again in a few minutes.',
    noCookieSet:
      'Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue.',
    passwordSameAsPrevious: 'Password is the same as the previous password.',
    sms: {
      exceeded:
        'Maximum amount of SMS sent is reached. After 10 minutes, you will be able to resend the SMS.',
      unexpected: 'Unexpected error.',
    },
    sessionExpired: 'Session expired. Please try again.',
    unknownError: 'Unexpected error occurred. Please try again.',
    sso: {
      loginFailed: 'SSO login error occurred.<br>Incident id: {{incidentId}}.',
    },
  },
  incorrectCredentials: 'Email and/or password is incorrect',
  invalidEmailFormat: 'Email address format is incorrect',
  forgotPassword: 'Forgot your password?',
  logIn: logInVerb.en,
  title: logInVerb.en + ' at myneva',
  maximumReachedTwoFactor:
    'Maximum number of attempts reached. Please contact your organisation.',
  missingPhoneInfo: 'In order to proceed, please provide us your phone number.',
  next: 'NEXT',
  notFoundPage: {
    invitation: {
      header: 'The invitation has expired',
      content:
        'If you are not able to find the problem, please contact your application manager',
    },
    loginMigration: {
      header: 'We have moved the login page!',
      content:
        'The login flow is now specific for your organisation.<br>Please contact your application manager(s) of your organisation for support.',
    },
    header: 'This is not the correct URL',
    content:
      'Please check the URL.<br>If you are not able to find the problem, please contact your application manager.',
  },
  loadingPage: {
    loading: 'Loading',
  },
  password: password.en,
  passwordRecovery: {
    forcePasswordChange:
      'The organisation decided that your password has to be changed periodically. You must now change your password',
    passwordRecoveryDoNotExists: 'Password recovery link was not found.',
    resetYourPassword: 'Reset your password',
    requestPasswordChangeBottomText: 'Enter your new password below',
    requestPasswordChangeUpperText: 'Reset your password',
    requestPasswordChangeButtonText: 'Reset Password',
    requestWasSentBottomText: 'Follow the instructions in the email',
    requestWasSentUpperText: 'Request was sent',
    resetPasswordRequestBottomText: "You'll receive an email with instructions",
  },
  passwordRequired: passwordRequired.en,
  policy: 'Privacy policy',
  registration: {
    idp: {
      activation: {
        title: 'Account activation',
        inProgress: 'Your account activation is in progress.',
        emailInfo:
          'You will receive an additional email shortly with further instructions to complete your account setup.',
        emailSpamInfo:
          'If you don’t receive the email within a few minutes, please check your spam folder or contact your application manager for assistance.',
      },
    },
    button: 'Register',
    error: {
      codeExpiredOrNotFound: 'Invitation has expired',
      activationCodeExpired: 'Activation code has expired',
      activationCodeNotFound:
        'Given activation code could not be found. Please contact the administrator.',
      emailNotUnique: 'Email address is in use',
      invalid: {
        code: 'Invalid verification code',
      },
      incorrectPhoneNumber: 'Phone number is incorrect.',
      passwordRequired: 'Password is required',
      passwordsDontMatch: 'Passwords do not match',
      passwordValidationError: {
        uppercaseRequired: 'Weak - at least one uppercase letter is required',
        lowercaseRequired: 'Weak - at least one lowercase letter is required',
        numberRequired: 'Weak - at least one number is required',
        minRequired: 'Weak - password is too short',
      },
      phoneNumberInvalidFormat: 'Phone number format is invalid',
      phoneNumberRequired: 'Phone number is required',
      confirmPasswordRequired: 'Password confirmation is required',
      unexpected: 'Unexpected error occurred. Please try again',
    },
    info: 'Create an account for',
    label: {
      phoneNumber: 'Mobile number',
      password: 'Set password',
      confirmPassword: 'Confirm password',
    },
    password: {
      strong: 'Strong',
      good: 'Good',
      fair: 'Fair',
    },
    placeholder: {
      phoneNumber: 'Type your phone number',
      password: 'Type your password',
      confirmPassword: 'Retype your password',
    },
  },
  twoFactorAuthenticationQrCode: {
    setupAuthentication:
      'The application manager has made two-factor authentication mandatory for logging in',
    scanQR:
      'Scan the QR-code with the authenticator, or manually type in the code above',
  },
  sendNew: 'SEND NEW CODE',
  sendNewCodeTimerInfo: 'Send new code in: ',
  smsAttemptsExceeded:
    "Didn't receive the code? Please contact your organisation",
  sso: {
    or: 'OR',
    placeholder: 'Select SSO option',
    login: 'SSO Login',
  },
  success: {
    passwordChange: 'Password has been changed successfully.',
    registrationCompleted: 'Registration completed successfully.',
  },
  terms: 'Terms of use',
  typeYourEmail: 'Type your email address',
  typeYourPassword: 'Type your password',
  username: username.en,
  usernameRequired: usernameRequired.en,
  surveyRequestDialog: {
    infoContent: 'Taking the survey will redirect you to a new page.',
    doNotPresent: 'Don’t remind me again',
    cancel: 'Not now',
    submit: 'Take survey',
    popupsBlockedAlert:
      'Your browser blocks opening of an additional tab. Please trust {{ url }} in the settings of your browser.',
  },
};
