import { Component, OnInit } from '@angular/core';
import {
  AbstractlogoutComponent,
  AuthenticationService as AuthService,
  CookieService,
  LoggingService,
  OrganisationService,
  OriginService,
  UserService,
  WindowRef,
} from '@regas/shared';
import { catchError, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  template: '',
})
export class AutologoutComponent extends AbstractlogoutComponent
  implements OnInit {
  constructor(
    public windowRef: WindowRef,
    public authenticationService: AuthService,
    public organisationService: OrganisationService,
    public originService: OriginService,
    public cookieService: CookieService,
    public loggingService: LoggingService,
    private readonly userService: UserService,
  ) {
    super(
      windowRef,
      authenticationService,
      organisationService,
      originService,
      cookieService,
      loggingService,
    );
  }

  public ngOnInit(): void {
    const organisation = localStorage.getItem('organisationSubstitution') || '';
    localStorage.removeItem('organisationSubstitution');
    this.initCallback(organisation);
  }

  public initCallback(substitution: string): void {
    if (this.authenticationService.isIdpEnabled()) {
      this.logOut(substitution);
    } else {
      this.userService
        .getIdpStatus(substitution)
        .pipe(
          take(1),
          tap(({ status }) => {
            this.authenticationService.persistIdpStatus(status);
            this.logOut(substitution);
          }),
          catchError(() => {
            this.logOut(substitution);
            return EMPTY;
          }),
        )
        .subscribe();
    }
  }
}
