import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translations } from '../../translations';
import { SsoErrorCode } from '../../services/sso/sso-error/sso-error.service';

@Component({
  selector: 'regas-idp-error',
  templateUrl: './idp-error.component.html',
  styleUrls: ['./idp-error.component.scss'],
})
export class IdpErrorComponent implements OnInit {
  readonly translations = translations;
  errorTranslationCode: string;

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.initErrorTranslationCode();
  }

  private initErrorTranslationCode(): void {
    switch (
      SsoErrorCode[this.activatedRoute.snapshot.params['errorCode']]?.toString()
    ) {
      case SsoErrorCode.OIDC_IP.toString():
      case SsoErrorCode.IP.toString():
        this.errorTranslationCode = 'error.ipRestricted';
        break;
      case SsoErrorCode.ACTIVATION_NOT_FOUND.toString():
        this.errorTranslationCode = 'registration.error.activationCodeNotFound';
        break;
      case SsoErrorCode.ACTIVATION_ERROR.toString():
        this.errorTranslationCode = 'error.activationExpired';
        break;
      case SsoErrorCode.USER_INACTIVE.toString():
        this.errorTranslationCode = 'error.userInactive';
        break;
      case SsoErrorCode.USER_NOT_FOUND.toString():
        this.errorTranslationCode = 'error.userNotFound';
        break;
      default:
        this.errorTranslationCode = 'error.idpError';
        break;
    }
  }
}
